<template>
  <div class="table-unit">
    <div class="list-data-header">
      <page-title :title="$t('supplier_products.title_register_unit_table_form_supplier')" />
      <common-button
        class="listMenu-scope"
        @action="handleValidateAndPrepareData"
        :label="$t('supplier_products.button_save_and_close')"
        v-ripple="false"
        id="scope_select"
        type="colored"
      />
    </div>
    <p class="description-page">{{ $t('supplier_products.description_register_unit_table_form_supplier') }}</p>
    <div class="category-table table-supplier-unit custom-table">
      <data-table
        :init-grid="initGrid"
        :data-source="unitTableGrid"
        :grid-columns="unitTableColumns"
        :allowRightClick="!isReadOnly"
        :isShowToolbar="false"
        :rowFocus="1"
        :showFocus="!isReadOnly"
        :isReadOnly="false"
        :allowDelete="!isReadOnly"
      />
    </div>
    <notification-popup :dialog="alertNotify" :message="messageNotification" @submit="alertNotify = false" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import PageTitle from '@/components/pageTitle/pageTitle';
import DataTable from '@/components/category/data-table';
import { CollectionView } from '@mescius/wijmo';
import * as wjcCore from '@mescius/wijmo';

import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { BLANK_ID } from '@/constants/registerData';
import { KEYS_CODE } from '@/constants/keyboard';
import CommonButton from '@/components/utils/button.vue';
import { MESSAGE_NOT_NULL } from '@/constants/registerData';
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
export default {
  components: {
    PageTitle,
    DataTable,
    CommonButton,
    NotificationPopup,
  },
  props: {
    typeForm: {
      type: String,
      default: 'create',
    },
    dataFormDetail: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      unitTableGrid: null,
      unitTableColumns: [],
      unitDataSource: [],
      itemsInView: ['name', 'activity_level_unit'],
      alertNotify: false,
      messageNotification: '',
    };
  },
  mounted() {
    this.defineTable(this.unitDataSource);
  },
  computed: {
    isReadOnly() {
      return this.typeForm === 'edit';
    }
  },
  watch: {
    dataFormDetail: {
      handler: function (newVal) {
        this.updateUnitDetail(newVal)
      }
    }
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingTable']),
    updateUnitDetail(newVal) {
      this.unitDataSource = newVal.template_unit;
      this.unitTableGrid = new CollectionView([...this.unitDataSource]);
      this.defineTable();
    },
    checkValidateAndShowPopupIfError() {
      let messageList = [];
      if (!this.processDetailInfo.productNumber) {
        messageList.push(this.$t('cfp_emissions_product.message_required_number_of_product'));
      }
      if (messageList.length > 0) {
        this.dialog.notification = true;
        this.message.notification = messageList.join('\n');
        return true;
      }
    },
    getError(item, propName) {
      if (this.itemsInView.every((field) => item[field] === null || item[field] === '' || item[field] === undefined)) {
        return null;
      }
      switch (propName) {
        case 'activity_level_unit':
          return this.checkRequiredField(item[propName], 'supplier_products.error_required_field_unit')
        case 'name':
          return this.checkRequiredField(item[propName], 'supplier_products.error_required_field_item_name')
      }
      return null;
    },
    checkRequiredField(item, message) {
      if (item === undefined || item === null || item === '') {
        return this.$t(message);
      }
      return validateMaximumCharactorOnlyFacility(String(item), 255);
    },
    prepareDataSubmit() {
      let editedBlank = {};
      let indexBlank = 0;
      let self = this;
      self.unitTableGrid.sourceCollection.forEach((record, indexWithBlankId) => {
        const everyValueNotChange = this.itemsInView.every((field) => {
          return record[field] === null || record[field] === '' || record[field] === undefined;
        });
        if (!everyValueNotChange) {
          const dataValuesMapping = {};
          this.itemsInView.forEach((binding) => {
            dataValuesMapping[binding] = record[binding];
          });
          let prepareData = dataValuesMapping;
          editedBlank[indexWithBlankId] = prepareData;
          indexBlank += 1;
        }
      });
      return editedBlank;
    },
    handleValidateAndPrepareData() {
      if (this.isReadOnly) {
        this.$emit('displayInputUnitScreen', false)
        return;
      }
      
      this.unitTableGrid.getError = this.getError;
      const dataSubmit = this.prepareDataSubmit();
      if (Object.values(dataSubmit).length <= 0) {
        this.alertNotify = true;
        this.messageNotification = this.$t('supplier_products.title_notify_send_data');
        return;
      }
      let errorData = [];
      this.unitTableGrid.sourceCollection.forEach((item) => {
        if (this.itemsInView.every((field) => item[field] === null || item[field] === '')) {
          return true;
        }
        this.flexGrid.columns.forEach((column) => {
          errorData.push(this.getError(item, column.binding));
          return true;
        });
      });
      errorData = errorData.filter((item) => item !== null);
      if (errorData.length > 0) {
        if (errorData.includes(MESSAGE_NOT_NULL)) {
          this.alertNotify = true;
          this.messageNotification = this.$t('register_data.popup_message_require_input');
        }
      } else {
        this.$emit('displayInputUnitScreen', false, Object.values(dataSubmit));
      }
    },
    defineTable() {
      this.unitTableColumns = this.getGridColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
   
    initGrid(grid) {
      this.flexGrid = grid;

      document.addEventListener('keydown', (e) => {
          if (
              (e.metaKey || e.ctrlKey) &&
              [
                  KEYS_CODE.DOWN_ARROW,
                  KEYS_CODE.UP_ARROW,
                  KEYS_CODE.LEFT_ARROW,
                  KEYS_CODE.RIGHT_ARROW,
                  KEYS_CODE.ENTER,
              ].includes(e.keyCode)
          ) {
              e.preventDefault();
          }
      });
      grid.hostElement.addEventListener(
          'keydown',
          (e) => {
              // console.log('keydown: ', e);
              if (e.metaKey || e.ctrlKey) {
                  if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
                      const currentSelection = grid.selection;
                      const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
                      grid.selection = cellRange;

                      // re-select after add more
                      setTimeout(() => {
                          grid.selection = cellRange;
                      }, 200);
                  } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
                      const currentSelection = grid.selection;
                      const cellRange = new wjGrid.CellRange(0, currentSelection.col);
                      grid.selection = cellRange;
                  } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
                      const currentSelection = grid.selection;
                      const cellRange = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
                      grid.selection = cellRange;
                  } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
                      const currentSelection = grid.selection;
                      const cellRange = new wjGrid.CellRange(currentSelection.row, 2);
                      grid.selection = cellRange;
                  }
              }

              if (e.keyCode === KEYS_CODE.ENTER) {
                  if (grid.selection.row === grid.rows.length - 1) {
                      const lastClientId = grid.itemsSource.itemCount;

                      grid.deferUpdate(() => {
                          grid.itemsSource.addNew(this.blankData(lastClientId + 1));
                          grid.itemsSource.commitNew();
                      });
                  }
              }
              if (e.keyCode === KEYS_CODE.KEY_V) {
                  this.actionUpdateIsLoadingTable(true)
                  const { row, row2 } = grid.selection
                  const isSingleRowPasted = row2 === row
                  if (isSingleRowPasted) {
                      setTimeout(() => { this.actionUpdateIsLoadingTable(false) }, 300)
                  }
              }
          },
          false,
      );
      grid.pasted.addHandler((s, e) => {
          const { col, col2, row, row2 } = e.range
          const view = s.collectionView
          setTimeout(() => {
              this.actionUpdateIsLoadingTable(false)
          }, 0)
      });
      grid.pastingCell.addHandler((s, e) => {
      })
      grid.deletedRow.addHandler(() => {
      })
    },
    initialView() {
      if (this.unitTableGrid) {
        this.unitDataSource = this.unitTableGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      this.unitTableGrid = new CollectionView([...this.unitDataSource], {
        trackChanges: true,
      });
      this.addBlankItemsToView(10);
      this.flexGrid.columnGroups = this.unitTableColumns;
      setMinMaxSizeColumns(this.flexGrid, this.unitDataSource);
      this.unitTableGrid.currentItem = null;
    },
    getGridColumns() {
      const widthTooltip = 20;
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_item_name'),
          binding: 'name',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_item_name'), widthTooltip),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: this.isReadOnly,
          wordWrap: true,
          align: 'left',
          isRequired: false,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('cfp_emissions_product.table_emissions_product_unit'),
          binding: 'activity_level_unit',
          minWidth: getWidthByTextContent(this.$t('cfp_emissions_product.table_emissions_product_unit'), widthTooltip),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: this.isReadOnly,
          wordWrap: true,
          align: 'left',
        },
      ];
    },
    addBlankItemsToView(count) {
      const lastClientId = this.unitTableGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.unitTableGrid.addNew(this.blankData(lastClientId + index));
      }

      this.unitTableGrid.commitNew();
    },
    blankData() {
      return {
        id: BLANK_ID,
        name: null,
        activity_level_unit: null,
      };
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
  },
};
</script>

<style scoped lang="scss">
.table-unit {
  padding-left: 40px;
}
.list-data-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.description-page {
  margin-bottom: 48px;
  color: $monoBlack;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.75px;
}
.submit-register-btn {
  min-width: 75px !important;
}
@media (max-width: 480px) {
  .custom-table {
    padding-left: 20px !important;
  }
}
@media (min-width: 480px) {
    .table-supplier-unit {
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      .category-table {
        width: fit-content;
      }
    }
  .custom-table {
    padding-right: 0 !important;
  }
}
</style>
